/**
 * Created by TapFun on 2022/11/10.
 */
import qs from "qs";
import request from '../common/request';
import {SERVER_HOST} from '../common/portConfig';

// 获取图片验证码：
export const reqCodeImg = params => {
    return request.get(`/getCodeImage`, {
        params: params,
        responseType: 'blob'
    })
};
// 登录
export const reqSystemLogin = params => {
    return request.post(`/wxLogin`, qs.stringify(params))
};
// 通过id获取用户信息
export const reqUserExternalOne = params => {
    return request.get(`/userExternal/one`, {params: params})
};
//帮助中心查看页面
export const reqGetHelpCenter = params => {
    return request.get(`/helpCenter/view`, {params: params})
};
// 用户修改密码
export const updateUserPwd = params => {
    return request.post(`/user/updatePwd`, qs.stringify(params))
};
// 文件上传
export const uploadFileURL = `${SERVER_HOST}/uploadFile`;
// 文件下载
export const downloadFileURL = `${SERVER_HOST}/downloadFile/`;
// 文件删除
export const reqDeleteFile = params => {
    return request.post(`/deleteFile`, params)
};
// 通过code查询可用的数据字典选项
export const reqGetDictionaryListByCode = params => {
    return request.get(`/dictionary/getListByCode`, {
        params: params
    })
};
// 通过code列表查询可用的数据字典选项map
export const reqDictionaryMapByCodeList = params =>{
    return request.post(`/dictionary/getMapByCodeList`, params)
}
