export function uuid() { //uuidv4: https://www.ietf.org/rfc/rfc4122.txt
    return ([1e7]+1e3+4e3+8e3+1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

// base64加密开始
export function encode64(input) {
    let keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    let output = "";
    let chr1, chr2, chr3 = "";
    let enc1, enc2, enc3, enc4 = "";
    let i = 0;
    do {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
            + keyStr.charAt(enc3) + keyStr.charAt(enc4);
        chr1 = chr2 = chr3 = "";
        enc1 = enc2 = enc3 = enc4 = "";
    } while (i < input.length);

    return output;
}

// 手机、座机校验
export function checkContactNumber(value) {
    if (!value) {
        return false;
    }

    let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
    let tel = /^0\d{2,3}-\d{7,8}$/; //座机
    return (mobile.test(value) || tel.test(value));
}

// 手机校验
export function checkMobile(value) {
    if (!value) {
        return false;
    }

    let mobile = /^1(3|4|5|6|7|8|9)\d{9}$/; //最新16手机正则
    return mobile.test(value);
}

// 邮箱校验
export function checkEmail(value) {
    if (!value) {
        return false;
    }

    let regex = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
    return regex.test(value);
}

// 日期(格式：YYYY-MM-DD)校验
export function checkDate(value) {
    if (!value) {
        return false;
    }

    let regex = /^(([1-9][0-9]{3})-(((0[13578]|1[02])-(0[1-9]|[12][0-9]|3[01]))|((0[469]|11)-(0[1-9]|[12][0-9]|30))|(02-(0[1-9]|[1][0-9]|2[0-8]))))|((([1-9][0-9])(0[48]|[2468][048]|[13579][26])|((0[48]|[2468][048]|[3579][26])00))-02-29)$/;
    return regex.test(value);
}

// 18位身份证号码合法性校验
export function check18IdNumber(value) {
    if (value && value.length === 18) {
        let myYear = value.substr(6, 4);
        let myMonth = value.substr(10, 2);
        let myDay = value.substr(12, 2);
        let birthday = myYear + '-' + myMonth + '-' + myDay;

        /*
        * 校验身份证出生日期
        * 1. 在1900年后
        * 2. 在当前日期之前
        * 3. 年月日格式合法
         */
        if (myYear < 1900 || new Date(birthday) >= new Date() || !checkDate(birthday)) {
            return false;
        }
        // 校验最后一位合法性
        let coefficient = [7,9,10,5,8,4,2,1,6,3,7,9,10,5,8,4,2];
        let remainderMap = new Map([[0,'1'], [1,'0'], [2,'X'], [3,'9'], [4,'8'], [5,'7'], [6,'6'], [7,'5'], [8,'4'], [9,'3'], [10,'2']]);
        let sumValue = 0;
        coefficient.forEach((item, idx) => {
            sumValue += item * value.charAt(idx);
        });

        let lastChar = remainderMap.get(sumValue % 11);
        return value.endsWith(lastChar);
    } else {
        return false;
    }
}

// 富文本长度校验
export function checkEditorContent(value, maxLength) {
    if (!value) {
        return false;
    }

    let content = value.replace(/<[^>]*>/g,'');
    return (content.length <= maxLength);
}

// 查找树节点名称路径
export function treeFindNamePath (tree, func, path = []) {
    if (!tree) return []
    for (const data of tree) {
        path.push(data.label)
        if (func(data)) return path
        if (data.children) {
            const findChildren = treeFindNamePath(data.children, func, path)
            if (findChildren.length) return findChildren
        }
        path.pop()
    }
    return []
}

// 根据id查询节点label
export function getLabelById(nodes, item, arr) {
    for (let el of nodes) {
        if (el.id === item) {
            arr.push(el.label);
        } else if (el.children) {
            getLabelById(el.children, item, arr);
        }
    }
    return arr;
}

// 根据id查询节点label
export function getFullLabelById(nodes, item, arr) {
    for (let el of nodes) {
        if (el.id === item) {
            arr.push(el.full_label);
        } else if (el.children) {
            getLabelById(el.children, item, arr);
        }
    }
    return arr;
}

// Base64 转文件
export function convertBase64UrlToFile(data, filename) {
    let arr = data.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {
        type: mime
    });
}

// Base64 转Blob
export function convertBase64UrlToBlob(urlData) {
    const type = "image/jpeg";
    let bytes = null;
    if (urlData.split(",").length > 1) {
        bytes = window.atob(urlData.split(",")[1]);
    } else {
        bytes = window.atob(urlData);
    }
    let ab = new ArrayBuffer(bytes.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], { type });
}
